import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Table from "../components/Table";
import { useNavigate } from "react-router-dom";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Label, Row, Col } from "reactstrap";
import { disableAccount } from "../redux/authSlice";
import { useDispatch } from "react-redux";
import Table2 from "../components/Table2";

export default function Home() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [errors, setErrors] = useState({});
  const [Time, setTime] = useState({ minutes: "", hours: "" });
  const [disableData, setDisableData] = useState({
    offline_name: "",
    offline_total_time: "",
  });

  const toggle = () => {
    setModal(!modal);
    setTime({ minutes: "", hours: "" });
    setDisableData({ offline_total_time: "", offline_name: "" });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setDisableData((prev) => {
      return { ...prev, [name]: value };
    });
    setErrors({
      ...errors,
      [e.target.name]: undefined,
    });
  };

  const validateForm = () => {
    const errors = {};
    if (!disableData.offline_name) {
      errors.offline_name = "Required*";
    }
    return errors;
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    }
    // eslint-disable-next-line
  }, []);

  const disableHandle = (e) => {
    e.preventDefault();
    const validationErrors = validateForm(disableData);
    if (Object.keys(validationErrors).length === 0) {
      dispatch(disableAccount({ disableData, dispatch, navigate }));
      toggle();
      setTime({ minutes: "", hours: "" });
      setDisableData({ offline_total_time: "", offline_name: "" });
    } else {
      setErrors(validationErrors);
    }
  };

  const handleChangeTime = (currentTime, changingTo) => {
    const { hours, minutes } = Time;
    if (changingTo === "minutes") {
      setTime((prevState) => ({ ...prevState, minutes: Number(currentTime) }));
      setDisableData((prevState) => ({ ...prevState, offline_total_time: hours * 60 + Number(currentTime) }));
    } else if (changingTo === "hours") {
      const totalMinutes = Number(currentTime) * 60;
      setTime((prevState) => ({ ...prevState, hours: currentTime }));
      setDisableData((prevState) => ({ ...prevState, offline_total_time: minutes + totalMinutes }));
    } else {
      setTime((prevState) => ({ ...prevState, hours: 8, minutes: "00" }));
      setDisableData((prevState) => ({ ...prevState, offline_total_time: Number(currentTime) }));
    }
  };

  return (
    <>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Disable Account</ModalHeader>
        <ModalBody>
          <Label>Offline Name</Label>
          <Input type="text" name="offline_name" onChange={handleChange} placeholder="Offline Name" />
          {errors.offline_name && (
            <p className="error" style={{ color: "red" }}>
              {errors.offline_name}
            </p>
          )}
          <Label>Offline Time </Label>

          <Row>
            <Col>
              <Input type="number" value={Time.hours} id="hours" min="0" max="8" required placeholder="Hours" onChange={(evt) => handleChangeTime(evt.target.value, "hours")} />
            </Col>
            <Col>
              <Input type="number" value={Time.minutes} id="minutes" min="0" max="60" required placeholder="Minutes" onChange={(evt) => handleChangeTime(evt.target.value, "minutes")} />
            </Col>
            <Col>
              <button class="btn btn-outline-primary" type="submit" onClick={() => handleChangeTime(480)}>
                8 hours
              </button>
            </Col>
          </Row>
          {disableData.offline_total_time > 480 && (
            <p className="error" style={{ color: "red" }}>
              Offline Time should'nt be greater then 8 hours!
            </p>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={disableHandle} disabled={disableData.offline_total_time > 480}>
            Confirm
          </Button>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <div className="home-page new-parent-div">
        <Navbar />
        <div className="table-1 new-parent-div-1">
          <div className="container container-custom">
            <div className="flex-row">
              <h2>Account Status</h2>{" "}
              <span>
                <button className="btn btn-danger" onClick={toggle}>
                  Disable Account
                </button>
              </span>
            </div>
            <Table />
          </div>
        </div>
        <Table2 />
      </div>
    </>
  );
}
