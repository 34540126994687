import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { enableAccount, getStatus } from "../redux/authSlice";
import { useNavigate } from "react-router-dom";

export default function Table() {
  const [visibleEntries, setVisibleEntries] = useState(5);

  const handleShowMore = () => {
    setVisibleEntries(visibleEntries + 5);
  };

  const handleHide = () => {
    setVisibleEntries(5);
  };
  const navigate = useNavigate();
  const { statusDetails } = useSelector((state) => state.slice);
  const dispatch = useDispatch();
  const accountNum = localStorage.getItem("account_number");
  const filteredData = statusDetails?.info?.filter((item) => item.ACCOUNT_NUMBER === accountNum);
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      dispatch(getStatus(navigate));
    }
    // eslint-disable-next-line
  }, []);

  const enableHandle = (ele) => {
    dispatch(enableAccount({ ele, dispatch, navigate }));
  };
  return (
    <>
      {" "}
      <div className="outer-table-div">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Offline By</th>
              <th scope="col">Offline To</th>
              <th scope="col">Zone</th>
              <th scope="col">Status</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredData?.length > 0 ? (
              filteredData?.slice(0, visibleEntries).map((ele, i) => {
                return (
                  <tr key={i}>
                    <th scope="row">
                      Disabled <span style={{ color: "#0d6efd" }}>#{i + 1}</span>{" "}
                    </th>
                    <td>
                      <span>{ele.INFORMATION}</span>
                    </td>
                    <td>
                      <span>{ele.offine_to}</span>
                    </td>
                    <td>
                      <span>{ele.FIRST_ZONE}</span>
                    </td>
                    <td>
                      <span style={{ color: "red" }}>Offline</span>
                    </td>
                    <td>
                      <button type="button" className="btn btn-outline-success" onClick={() => enableHandle(ele)}>
                        Enable
                      </button>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr className="textAlign">
                <td colSpan="6">
                  <h2 style={{ color: "green" }}>Account is Online !</h2>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="row">
        <div className="col-lg-12" style={{ textAlign: "end" }}>
          {visibleEntries < filteredData?.length ? (
            <button className="show-div" onClick={handleShowMore}>
              +Show more
            </button>
          ) : visibleEntries > 5 ? (
            <button className="show-div" onClick={handleHide}>
              -Hide
            </button>
          ) : null}
        </div>
      </div>
    </>
  );
}
