import React from "react";

import loader from "../../assets/images/loading-gif.webp";

export default function SpinnerLoading() {
  return (
    <div className="loader-main">
      <div>
        <div className="container_loader">
          {" "}
          <img src={loader} width={100} height={100} alt="loader"/>{" "}
        </div>
      </div>
    </div>
  );
}
