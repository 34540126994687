import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAccountHistory } from "../redux/authSlice";
import { useNavigate } from "react-router-dom";

function Table2() {
  const [date, setDate] = useState({
    start_date: "",
    end_date: "",
  });
  const [visibleEntries, setVisibleEntries] = useState(10);

  const handleShowMore = () => {
    setVisibleEntries(visibleEntries + 10);
  };

  const handleHide = () => {
    setVisibleEntries(10);
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { accountHistoryDetail } = useSelector((state) => state.slice);

  useEffect(() => {
    const today = new Date();
    const formattedStartDate = today.toISOString().split("T")[0];
    const formattedEndDate = today.toISOString().split("T")[0];

    setDate({
      start_date: formattedStartDate,
      end_date: formattedEndDate,
    });

    dispatch(getAccountHistory({ date: { start_date: formattedStartDate, end_date: formattedEndDate }, navigate }));
  }, [dispatch, navigate]);

  const handleStartDateChange = (event) => {
    setDate({
      ...date,
      start_date: event.target.value,
    });
  };

  const handleEndDateChange = (event) => {
    setDate({
      ...date,
      end_date: event.target.value,
    });
  };

  const submit = () => {
    dispatch(getAccountHistory({ date, navigate }));
  };

  return (
    <div>
      <div className="table-1 new-parent-div-1 table-parent-new2">
        <div className="container container-custom">
          <div className="flex-row">
            <h2>Account History</h2>
          </div>
          <div className="row">
            <div className=" col-lg-12">
              <div className="table-2-date">
                <div className="m-3">
                  <label htmlFor="date" className="form-label">
                    Start Date
                  </label>
                  <input type="date" value={date.start_date} name="start_date" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" onChange={handleStartDateChange} />
                </div>
                <div className="m-3">
                  <label htmlFor="exampleInputPassword1" className="form-label">
                    End date
                  </label>
                  <input type="date" value={date.end_date} name="end_date" className="form-control" id="exampleInputPassword1" onChange={handleEndDateChange} />
                </div>
                <div className="m-3">
                  <label htmlFor="date" className="form-label"></label>
                  <button type="submit" onClick={submit} className="btn btn-primary date-btn">
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="outer-table-div">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Date/Time</th>
                  <th scope="col">Code</th>
                  <th scope="col">Zone</th>
                  <th scope="col">Area</th>
                  <th scope="col">Type</th>
                  <th scope="col">Detail</th>
                </tr>
              </thead>
              <tbody>
                {accountHistoryDetail?.data?.length > 0 ? (
                  accountHistoryDetail?.data?.slice(0, visibleEntries).map((ele, i) => {
                    return (
                      <tr key={i}>
                        <th scope="row">
                          <span>{ele.HISTORY_EVENT_NTD ? ele.HISTORY_EVENT_NTD : "--"}</span>
                        </th>
                        <td>
                          <span>{ele.ZONE_CODE_1 ? ele.ZONE_CODE_1 : "--"}</span>
                        </td>
                        <td>
                          <span>{ele.ZONE_CODE_2 ? ele.ZONE_CODE_2 : "--"}</span>
                        </td>
                        <td>
                          <span>{ele.AREA ? ele.AREA : "--"}</span>
                        </td>
                        <td>
                          <span>{ele.TYPE ? ele.TYPE : "--"}</span>
                        </td>
                        <td>
                          <span>{ele.HIST_TEXT ? ele.HIST_TEXT : "--"}</span>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr className="textAlign">
                    <td colSpan="6">
                      <h2 style={{ color: "green" }}>No History Found !</h2>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="row">
            <div className="col-lg-12" style={{ textAlign: "end" }}>
              {visibleEntries < accountHistoryDetail?.data?.length ? (
                <button className="show-div" onClick={handleShowMore}>
                  +Show more
                </button>
              ) : visibleEntries > 10 ? (
                <button className="show-div" onClick={handleHide}>
                  -Hide
                </button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Table2;
