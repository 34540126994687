import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../common/common";
import { toast } from "react-toastify";

const initialState = {
  isLoading: false,
  userDetails: {},
  statusDetails: [],
  accountHistoryDetail: [],
};

export const userLogin = createAsyncThunk("counter/userLogin", async (data) => {
  const param = data.loginDetails;
  const navigate = data.navigate;
  const requestOptions = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  let formdata = new FormData();
  formdata.append("account_number", param.account_number);
  formdata.append("password", param.password);
  formdata.append("street_no", param.street_no);
  try {
    const response = await axios.post(`${BASE_URL}/login`, formdata, requestOptions);

    if (`${response.data.status}` === "error") {
      toast.error(response.data.message);
      return { ...response.data, error: true };
    } else {
      toast.success("Login successful!");
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("account_number", response.data.account_number);

      localStorage.setItem("company_name", response.data.company_name);
      localStorage.setItem("street_no", response.data.street_no);
      localStorage.setItem("street_no_1", response.data.street_no_1);
      localStorage.setItem("street_no_2", response.data.street_no_2);
      localStorage.setItem("city", response.data.city);
      localStorage.setItem("state", response.data.state);
      
      navigate("/");
      return { ...response.data, error: false };
    }
  } catch (error) {
    if (error.response.status === 401) {
      toast.error(error.response.data.message);
      navigate("/login");
      localStorage.removeItem("token");
      localStorage.removeItem("account_number");

      localStorage.removeItem("company_name");
      localStorage.removeItem("street_no");
      localStorage.removeItem("street_no_1");
      localStorage.removeItem("street_no_2");
      localStorage.removeItem("city");
      localStorage.removeItem("state");

    } else {
      toast.error("Something went wrong!");
    }
  }
});

export const getStatus = createAsyncThunk("counter/getStatus", async (navigate) => {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
  };
  const id = localStorage.getItem("account_number");
  try {
    const response = await axios.get(`${BASE_URL}/status/${id}`, requestOptions);

    if (response.data.error) {
      toast.error(response.data.message);
      return { ...response.data, error: true };
    } else {
      return { ...response.data, error: false };
    }
  } catch (error) {
    if (error.response.status === 401) {
      toast.error(error.response.data.message);
      navigate("/login");
      localStorage.removeItem("token");
      localStorage.removeItem("account_number");
    } else {
      toast.error("Something went wrong!");
    }
  }
});

export const enableAccount = createAsyncThunk("counter/enableAccount", async ({ ele, dispatch, navigate }) => {
  const requestOptions = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: localStorage.getItem("token"),
    },
  };
  const id = localStorage.getItem("account_number");
  let formdata = new FormData();
  formdata.append("unique_key", ele.UNIQUE_KEY);
  formdata.append("account_id", id);
  try {
    const response = await axios.post(`${BASE_URL}/enable_account`, formdata, requestOptions);

    if (response.data.error) {
      toast.error(response.data.message);
      return { ...response.data, error: true };
    } else {
      toast.success("Enabled successfully!");
      dispatch(getStatus());
      return { ...response.data, error: false };
    }
  } catch (error) {
    if (error.response.status === 401) {
      toast.error(error.response.data.message);
      navigate("/login");
      localStorage.removeItem("token");
      localStorage.removeItem("account_number");
    } else {
      toast.error("Something went wrong!");
    }
  }
});

export const disableAccount = createAsyncThunk("counter/disableAccount", async ({ disableData, dispatch, navigate }) => {
  const requestOptions = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: localStorage.getItem("token"),
    },
  };
  const id = localStorage.getItem("account_number");
  let formdata = new FormData();
  formdata.append("offline_total_time", disableData.offline_total_time);
  formdata.append("offline_name", disableData.offline_name);
  formdata.append("account_number", id);
  try {
    const response = await axios.post(`${BASE_URL}/disable_account`, formdata, requestOptions);

    if (response.data.error) {
      toast.error(response.data.message);
      return { ...response.data, error: true };
    } else {
      toast.success("Disabled successfully!");
      dispatch(getStatus());
      return { ...response.data, error: false };
    }
  } catch (error) {
    if (error.response.status === 401) {
      toast.error(error.response.data.message);
      navigate("/login");
      localStorage.removeItem("token");
      localStorage.removeItem("account_number");
    } else {
      toast.error("Something went wrong!");
    }
  }
});

export const getAccountHistory = createAsyncThunk("counter/getAccountHistory", async ({ date, navigate }) => {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
  };
  // const id = localStorage.getItem("account_number");
  try {
    const response = await axios.get(`${BASE_URL}/get-history?start_date=${date.start_date}&end_date=${date.end_date}`, requestOptions);

    if (response.data.error) {
      toast.error(response.data.message);
      return { ...response.data, error: true };
    } else {
      return { ...response.data, error: false };
    }
  } catch (error) {
    if (error.response.status === 401) {
      toast.error(error.response.data.message);
      navigate("/login");
      localStorage.removeItem("token");
      localStorage.removeItem("account_number");
    } else {
      toast.error("Something went wrong!");
    }
  }
});

export const counterSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    categoryadd: (state, action) => {
      state.category = action.payload;
    },
  },

  extraReducers: {
    [userLogin.pending]: (state) => {
      state.isLoading = true;
    },
    [userLogin.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.userDetails = action.payload;
    },
    [userLogin.rejected]: (state, action) => {
      state.isLoading = false;
    },

    [getStatus.pending]: (state) => {
      state.isLoading = true;
    },
    [getStatus.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.statusDetails = action.payload;
    },
    [getStatus.rejected]: (state, action) => {
      state.isLoading = false;
    },

    [enableAccount.pending]: (state) => {
      state.isLoading = true;
    },
    [enableAccount.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [enableAccount.rejected]: (state, action) => {
      state.isLoading = false;
    },

    [disableAccount.pending]: (state) => {
      state.isLoading = true;
    },
    [disableAccount.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [disableAccount.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getAccountHistory.pending]: (state) => {
      state.isLoading = true;
    },
    [getAccountHistory.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.accountHistoryDetail = action.payload;
    },
    [getAccountHistory.rejected]: (state, action) => {
      state.isLoading = false;
    },
  },
});

export const { categoryadd } = counterSlice.actions;
export default counterSlice.reducer;
