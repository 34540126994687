import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userLogin } from "../redux/authSlice";
export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loginDetails, setLoginDetails] = useState({
    account_number: "",
    password: "",
    street_no: "",
  });
  const [errors, setErrors] = useState({});
  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginDetails((prev) => {
      return { ...prev, [name]: value };
    });
    setErrors({
      ...errors,
      [e.target.name]: undefined,
    });
  };
  const validateForm = () => {
    const errors = {};

    if (!loginDetails.account_number) {
      errors.account_number = "Required*";
    }

    if (!loginDetails.password) {
      errors.password = "Required*";
    }

    if (!loginDetails.street_no) {
      errors.street_no = "Required*";
    }

    return errors;
  };

  const loginHandle = (e) => {
    e.preventDefault();
    const validationErrors = validateForm(loginDetails);
    if (Object.keys(validationErrors).length === 0) {
      dispatch(userLogin({ loginDetails, navigate }));
    } else {
      setErrors(validationErrors);
    }
  };
  return (
    <>
      <div className="login-body">
        <div className="wrapper">
          <div className="login">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="login-form section">
                    <div className="row justify-content-center mt-5 pt-5">
                      <div className="col-md-6 col-lg-5 col-xl-4">
                        <div className=" login-card">
                          <div className="logo text-center mb-3">
                            <div className="mt-3">
                              <h1 className="font-yeseva">Login</h1>
                            </div>
                          </div>
                          <form className="card p-3 rounded-3 border-0" onSubmit={loginHandle}>
                            <div className="mb-3">
                              <label htmlFor="exampleInputEmail1" className="form-label">
                                Account #
                              </label>
                              <input type="text" className="form-control" id="exampleInputEmail1" name="account_number" onChange={handleChange} placeholder=" Account #" />
                              {errors.account_number && (
                                <p className="error" style={{ color: "red" }}>
                                  {errors.account_number}
                                </p>
                              )}
                            </div>
                            <div className="mb-3">
                              <label htmlFor="exampleInputPassword1" className="form-label">
                                Password of account
                              </label>
                              <input type="password" className="form-control" id="exampleInputPassword1" name="password" onChange={handleChange} placeholder="Password of account" />
                              {errors.password && (
                                <p className="error" style={{ color: "red" }}>
                                  {errors.password}
                                </p>
                              )}
                            </div>
                            <div className="mb-3">
                              <label htmlFor="exampleInputPassword1" className="form-label">
                                Street # of location only. Not street name
                              </label>
                              <input type="text" className="form-control" id="exampleInputPassword1" name="street_no" onChange={handleChange} placeholder="Street # of location only" />
                              {errors.street_no && (
                                <p className="error" style={{ color: "red" }}>
                                  {errors.street_no}
                                </p>
                              )}
                            </div>

                            <div className="d-flex align-items-center justify-content-between my-3"></div>
                            <div className="mb-3">
                              <p>If there's no password on account, re-enter your account # in password field </p>
                              <p>If you are having issues logging in, please call us and ask a representative to verify your information</p>
                            </div>
                            <button type="submit" className="btn btn-primary custm-btn primary-bg border-0" onClick={loginHandle}>
                              Login
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
