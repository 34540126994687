import React from "react";
import { useNavigate } from "react-router-dom";
export default function Navbar() {
  const navigate = useNavigate();
  const logoutHandle = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("account_number");
    localStorage.removeItem("company_name");
    localStorage.removeItem("street_no");
    localStorage.removeItem("street_no_1");
    localStorage.removeItem("street_no_2");
    localStorage.removeItem("city");
    localStorage.removeItem("state");


    navigate("/login");
  };
  let id = localStorage.getItem("account_number");

  let company_name = localStorage.getItem("company_name") != null ? localStorage.getItem("company_name") : "";
  let street_no = localStorage.getItem("street_no") != null ? localStorage.getItem("street_no") : "";
  let street_no_1 = localStorage.getItem("street_no_1") != null ? localStorage.getItem("street_no_1") : "";
  let street_no_2 = localStorage.getItem("street_no_2") != null ? localStorage.getItem("street_no_2") : "";
  let city =      localStorage.getItem("city") != null ? localStorage.getItem("city") : "";
  let state = localStorage.getItem("state") != null ? localStorage.getItem("state") : "";
  

  return (
    <nav className="navbar navbar-expand-lg bg-white">
      <div className="container-fluid">
        <div className="flex-header">
            <h2 className="navbar-brand">
          <span style={{ color: "#0d6efd" }}>#</span>
          {id +" ( "+ company_name + ", " + street_no + " "+ street_no_1 + " " + street_no_2 + ", "+ city +", "+state+" )" }
        </h2>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        </div>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <form className="d-flex" role="search">
            <button className="btn btn-outline-primary" type="submit" onClick={logoutHandle}>
              Logout
            </button>
          </form>
        </div>
      </div>
    </nav>
  );
}
